@tailwind base;
@tailwind components;
@tailwind utilities;

.tketag-bg {
  background-image: url("https://tketag.unkreativ.art/logo.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
